import React from "react";

function Loading() {
    return (
        <section className="Loading">
            
            <div>Page Loading...</div>
        </section>  
    );
}

export default Loading;