import React from "react";
import {
    HashRouter  as Router,
    Switch,
    Route,
} from "react-router-dom";
  
import loadable from '@loadable/component';
import { loadableReady } from '@loadable/component';

import Loading from "./components/Loading.js";

import "./App.scss";
import Header from "./components/header/Header"; 
import Footer from "./components/footer/Footer";

const LandingPage = loadable( ()=> import('./LandingPage'), {fallback: <Loading />} );
// import LandingPage from "./LandingPage";

const AboutUs = loadable( () => import('./sub-pages/AboutUs'), {fallback: <Loading />} );
// import AboutUs from "./sub-pages/AboutUs";

const ContactUs = loadable(() => import('./sub-pages/ContactUs'), {fallback: <Loading />});
//import ContactUs from "./sub-pages/ContactUs";

import SimpleReactLightbox from 'simple-react-lightbox';

const Gallery = loadable(() => import('./gallery/Gallery'), {fallback: <Loading />});
// import Gallery from "./gallery/Gallery";


//const NotFoundPage = lazy(() => import('./sub-pages/NotFoundPage'));
import NotFoundPage from './sub-pages/NotFoundPage';

  
// import logoSquare from './images/logo.png';
// import Button from 'react-bootstrap/Button';
// import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    

    return (
        <React.StrictMode>
            <SimpleReactLightbox>
            <Router>
        <div className="App">
            

                <Header />
                
                        

                <Switch>                        
                        <Route exact path="/" component={LandingPage} />
                        <Route path="/about-us" component={AboutUs} />                
                        <Route path="/contact-us" component={ContactUs} />
                        <Route path="/gallery" component={Gallery} />
                    
                    <Route path="*" component={NotFoundPage} />

                </Switch>
                
                      
                <Footer />
                    


                {/* <LandingPage /> */}
{/*                                 
                <div className="container">
                    <h2>Welcome to React App</h2>
                    <h3>Date: {new Date().toDateString()}</h3>
                    
                    <img src={logoSquare} className="logoSquare" />

                    <Button variant="primary">Primary</Button>{' '}
                    <Button variant="secondary">Secondary</Button>{' '}
                    <Button variant="success">Success</Button>{' '}
                    <Button variant="warning">Warning</Button>{' '}
                    <Button variant="danger">Danger</Button> <Button variant="info">Info</Button>{' '}
                    <Button variant="light">Light</Button> <Button variant="dark">Dark</Button>{' '}
                    <Button variant="link">Link</Button>
                </div> */}


            
        </div>
        </Router>
                
            </SimpleReactLightbox>
        </React.StrictMode>
    )
}


export default App;