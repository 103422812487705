import React from "react";

import "./Footer.scss";


function Footer() {
    return (
        <footer className="Footer">          
            <div className="footer-bottom border-top border-4 border-primary">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-12">
                            <p className="mb-0">Quality Construction &amp; Interior</p>
                
                        </div>
                        <div className="col-lg-6 col-md-6 col-12">
                            <p className="mb-0 text-md-end">All Right Reserved &copy; {(new Date().getFullYear())} by qconstruct.in</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default Footer;