//To run
//npm run start-dev

//We’ll need to tell our React app where to hook into the DOM (in our index.html)

import React from "react";
import ReactDOM, { hydrate } from "react-dom";

import { loadableReady } from '@loadable/component';
import App from "./App";


// loadableReady(() => {
//     const root = document.getElementById('root')
//     hydrate(<App />, root)
// })
  
ReactDOM.render(<App />, document.getElementById("root"));
/*ReactDOM.render is the function that tells React what to render and where to render it*/