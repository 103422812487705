import React from 'react';
import "./NotFoundPage.scss";

import { useLocation, Link } from 'react-router-dom';
import NotFoundPage_bg from './../images/NotFoundPage.png';

function NotFoundPage() {
  let location = useLocation();
  
  return (
    <div className="NotFoundPage">
      <div className="container">
        <div className="oops text-center mt-4">Oops!</div>        
        <div className="text-center mb-n2">
          PAGE NOT FOUND
          <span className="d-block text-center">Error</span>
        </div>
        
        <div className="NotFoundPage_bg" style={{ backgroundImage: `url(${NotFoundPage_bg})` }}>

        </div>
        
        <div className="text-center">
          Please Check the url entered <code>{location.pathname}</code> may be wrong, or the page not exists.
          <span className="d-block fw-bold mb-2">Click below for Home Page</span>

          <Link to="/" className="btn btn-primary">Go to Home </Link>
          
        </div>
      </div>
    </div>
  );
}
export default NotFoundPage;